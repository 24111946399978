<template>
  <v-card class="pa-4">
    <v-card-title
      class="text-subtitle-1 font-weight-bold"
    >
      <v-row no-gutters>
        <v-col cols="10">
          {{ $t("languagesSettings.checklists.debtRecoveryTitle") }}
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="addNew"
          >
            {{ $t("languagesSettings.addNew") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item={item}>
          <tr>
            <td v-for="lang in languages" :key="lang.nameISO">
              <v-text-field
                v-model="item[lang.nameISO]"
                outlined
                dense
                single-line
                hide-details
                @blur="dirtBlur(item)"
              ></v-text-field>
            </td>
            <td class="d-flex justify-end align-center">
              <v-btn
                :disabled="isSaveButtonDisabled(item)"
                class="mr-2"
                color="primary"
                depressed
                @click="saveItem(item)"
              >
                {{ $t("common.save") }}
              </v-btn>
              <v-btn
                outlined
                depressed
                color="error"
                @click="removeItem(item)"
              >
                {{ $t("common.remove") }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DebtRecoveryChecklistTab',
  props: {
    translations: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  computed: {
    tableHeaders () {
      const headers = this.languages.map(lang => {
        const header = {
          text: lang.nameISO,
          value: lang.nameISO
        }
        return header
      })

      headers.push({ text: '', value: 'actions', width: '200px' })
      return headers
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    saveItem (item) {
      this.$emit('saved', item)
    },
    isSaveButtonDisabled (item) {
      if (!item.dirtField) {
        return true
      }

      for (const prop in item) {
        if (prop !== 'id') {
          if (item[prop].length === 0) {
            return true
          }
        }
      }

      return false
    },
    addNew () {
      if (!this.items.some(item => !item.id)) {
        const item = {
          departmentType: 1
        }
        this.languages.forEach(lang => {
          item[lang.nameISO] = ''
        })
        this.items.push(item)
      }
    },
    removeItem (removeItem) {
      if (removeItem.id) {
        this.$emit('removed', removeItem)
      } else {
        this.items = this.items.filter(item => !!item.id)
      }
    },
    mapItems (items) {
      return items.filter(item => !item.deleted).map(item => {
        const val = {
          id: item.id,
          departmentType: item.departmentType,
          dirtField: false
        }
        item.translatedValues.forEach(value => {
          val[value.language] = value.name
        })
        return val
      })
    },
    dirtBlur (item) {
      item.dirtField = true
    }
  },
  mounted () {
    this.items = this.mapItems(this.translations)
  },
  watch: {
    translations () {
      this.items = this.mapItems(this.translations)
    }
  }
}
</script>
